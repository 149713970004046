import * as R from "ramda";
import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown/with-html";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import BackgroundImage from "gatsby-background-image";
import { LinkRenderer } from "../utils/utilities";

//components
import HubspotForm from "../components/non-panels/forms/HubSpotForm";
import { EbookLeadMagnetFormReplacementMessage } from "../components/non-panels/forms/replacementElements";
import SEO from "../components/seo";
import useSetPageData from "../utils/useSetPageData";

//styles
import s from "./lp.module.scss";

const bookCover = R.view(R.lensPath(["bookCover", "childImageSharp", "fluid"]));

const mainImage = R.view(R.lensPath(["headerBackground", "publicURL"]));

const contentView = R.view(R.lensPath(["content", "edges", 0, "node"]));

const getHeaderBackground = R.view(
  R.lensPath(["headerBackground", "childImageSharp", "fluid"])
);

function stopFormAtFooter(setIsVisible) {
  const options = {
    root: null
  };

  return window => {
    const sentinel = window.document.querySelector(`.${s.sentinel}`);
    const observer = new window.IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    observer.observe(sentinel);

    return () => observer.disconnect();
  };
}

export default props => {
  const qData = useStaticQuery(graphql`
    query MWMQuery {
      logoRounded: file(relativePath: { eq: "logo-rounded-1536x361.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      headerBackground: file(relativePath: { eq: "outside_the_box.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1140) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bookCover: file(
        relativePath: { eq: "making_work_matter_book_cover_image1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1140) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mainImage: file(
        relativePath: { eq: "making_work_matter_cover_image.png" }
      ) {
        publicURL
      }
      content: allMarkdownRemark(
        filter: { frontmatter: { title: { eq: "Making Work Matter" } } }
        limit: 1
      ) {
        edges {
          node {
            rawMarkdownBody
            frontmatter {
              title
            }
          }
        }
      }
    }
  `);

  const headerBackgoundFluid = getHeaderBackground(qData);
  const bookCoverFluid = bookCover(qData);
  const mainImageURL = mainImage(qData);
  const content = contentView(qData);

  const [isVisible, setIsVisible] = useState(null);

  useSetPageData(props.location);

  useEffect(() => {
    return R.ifElse(
      R.isNil,
      R.always(() => null),
      stopFormAtFooter(setIsVisible)
    )(window);
  }, []);

  return (
    <>
      <SEO
        {...{
          title: `Making Work Matter E-book`,
          description:
            "Take busywork out of the equation for happier employees, increased productivity and to make work really matter."
        }}
        meta={
          mainImageURL
            ? [
                {
                  property: "og:image",
                  content: mainImageURL
                }
              ]
            : []
        }
      />
      <div className={`${s.wpContainer}`}>
        <BackgroundImage
          fluid={[
            "linear-gradient(rgba(43, 32, 112, 0.405), rgba(43, 32, 112, 0.99))",
            headerBackgoundFluid
          ]}
          Tag="section"
          className={`${s.wpHeader}`}
        >
          <div className="container">
            <div className="row">
              <div className="col-md">
                <div className={s.headerTitleContent}>
                  <h4>Download the new E-book</h4>
                  <h1>Making Work Matter</h1>
                </div>
                <div className={s.headerTextContent}>
                  <p>
                    "Take busywork out of the equation for happier employees,
                    increased productivity and to make work really matter."
                  </p>
                  <a
                    href="#form"
                    id="#button"
                    className={`btn btn-secondary btn-lg ${s.getButton}`}
                  >
                    Get the Ebook
                  </a>
                </div>
              </div>
              <div className="col-md"></div>
            </div>
          </div>
        </BackgroundImage>
        <article className="container">
          <div className="row">
            <div className={`${s.wpContent} col-md`}>
              <div className={s.imageWrapper}>
                <Img fluid={bookCoverFluid} />
              </div>
              <ReactMarkdown
                className={s.textWrapper}
                source={content.rawMarkdownBody}
                escapeHtml={false}
                renderers={{ link: LinkRenderer }}
              />
            </div>
            <div className="col-md">
              <div
                id="form"
                className={`${s.wpForm} ${isVisible ? s.abs : s.fixed}`}
              >
                <h4>Please fill out the form to download your copy</h4>
                <div className={s.formContainer}>
                  <HubspotForm
                    formId="14b05dce-1ffe-471e-8fe8-bbff7f1a923c"
                    replacementElement={
                      <EbookLeadMagnetFormReplacementMessage
                        downloadLink={
                          "/uploads/E-book_Making_Work_Matter_CoBranded.pdf"
                        }
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </article>
        <i className={`${s.sentinel}`}></i>
      </div>
    </>
  );
};
